/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import {
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { DispatchDragNDropTypes, DispatchOperationType } from '~/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import PF2MEquipIcon from '~/components/PF2MEquipIcon';
import PF2MDispatchIcon from '~/components/PF2MDispatchIcon';
import { setSelectedTruckOrigin, setSelectedLoadOrigin } from '~/store/dispatch/actions';

const getWarningType = (truckOrigin, loadAllocations, isGenericRow) => {
  if (isGenericRow) return 'standardWarning';
  const expectedLoadAllocation = loadAllocations
    .find(r => r.id === truckOrigin.load_allocation_id);

  if (expectedLoadAllocation
    && truckOrigin.current_excavator_id !== expectedLoadAllocation.equip_id) {
    return 'standardWarning';
  }
  return null;
};

const OriginRow = ({
  onTruckDrop, origin, leftLane, rightLane, isGenericRow = false,
  loadAllocations, classes, openTruckModal, openExcavatorModal, onTruckContextMenu,
  onLoadContextMenu,
}) => {
  const order = [
    DispatchOperationType.LOAD,
    DispatchOperationType.UNLOAD,
    DispatchOperationType.LOAD_MANEUVER,
    DispatchOperationType.UNLOAD_MANEUVER,
    DispatchOperationType.LOAD_QUEUE,
    DispatchOperationType.UNLOAD_QUEUE,
    DispatchOperationType.LOAD_TRAFFIC,
    DispatchOperationType.UNLOAD_TRAFFIC,
  ];

  const { t: translate } = useTranslation();
  const equipmentStatuses = useSelector(state => state.dispatch.equipmentStatuses);
  const codes = useSelector(state => state.manager.codes);
  const operators = useSelector(state => state.manager.operators);

  const leftLaneSorted = useMemo(() => leftLane.sort(
    (a, b) => order.indexOf(a.operation_type) - order.indexOf(b.operation_type),
  ), [leftLane, order]);

  const leftLaneDurations = leftLaneSorted.map((left) => {
    const matchingStatuses = equipmentStatuses.filter(
      status => status.id_equip === left.equip_id,
    );

    const durations = matchingStatuses.flatMap(status => status.duration);

    const mappedStatuses = matchingStatuses.map((status) => {
      const foundCode = codes.find(c => c.id === status.id_code);
      const foundOperator = operators.find(op => op.id_operator === status.id_operator);

      return {
        ...status,
        codeName: foundCode ? foundCode.name : 'Code não encontrado',
        operatorName: foundOperator ? foundOperator.name : 'Operador nao encontrado',
      };
    });

    const codesNames = mappedStatuses.map(status => status.codeName);
    const operatorsNames = mappedStatuses.map(status => status.operatorName);

    return {
      ...left,
      durations,
      codes_name: codesNames,
      operators_name: operatorsNames,
    };
  });

  const rightLaneSorted = useMemo(() => rightLane.sort(
    (a, b) => order.indexOf(a.operation_type) - order.indexOf(b.operation_type),
  ), [rightLane, order]);

  const rightLaneDurations = rightLaneSorted.map((right) => {
    const matchingStatuses = equipmentStatuses.filter(
      status => status.id_equip === right.equip_id,
    );

    const durations = matchingStatuses.flatMap(status => status.duration);

    const mappedStatuses = matchingStatuses.map((status) => {
      const foundCode = codes.find(c => c.id === status.id_code);
      const foundOperator = operators.find(op => op.id_operator === status.id_operator);

      return {
        ...status,
        codeName: foundCode ? foundCode.name : translate('common:CodeNotFound'),
        operatorName: foundOperator ? foundOperator.name : translate('common:OperatorNotFound'),
      };
    });

    const codesNames = mappedStatuses.map(status => status.codeName);
    const operatorsNames = mappedStatuses.map(status => status.operatorName);

    return {
      ...right,
      durations,
      codes_name: codesNames,
      operators_name: operatorsNames,
    };
  });

  const dispatch = useDispatch();
  const [{ dragItem }, truckDrop] = useDrop({
    accept: [DispatchDragNDropTypes.ORIGIN_TRUCK, DispatchDragNDropTypes.POOL_TRUCK],
    drop: (monitor) => {
      if (origin.id !== null
        && monitor.obj.current_excavator_id !== origin.equip_id) {
        onTruckDrop(monitor.obj, origin.id);
      }
    },
    collect: monitor => ({
      dragItem: monitor.getItem(),
    }),
  });

  return (
    <div
      key={origin.id}
      style={{
        display: 'flex',
        width: '100%',
        height: 110,
      }}
      ref={truckDrop}
    >
      <PF2MScrollbar
        className={classes.scrollbars}
        style={{ width: '50%' }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            backgroundColor: '#dedede',
          }}
        >
          <PF2MDispatchIcon
            iconType={'stack'}
            customStyles={{
              marginLeft: 15,
              marginRight: 15,
              cursor: 'default',
            }}
          />
          {
            leftLaneDurations.map(r => (
              <PF2MEquipIcon
                key={r.equip_id}
                extraInfo={`${r.current_destination_point_name}`}
                item={r}
                itemType={DispatchDragNDropTypes.ORIGIN_TRUCK}
                useOperationType
                customStyles={{
                  marginRight: 15,
                  opacity: (dragItem && dragItem.obj?.equip_id === r.equip_id) ? 0 : 1,
                }}
                warningType={getWarningType(r, loadAllocations, isGenericRow)}
                onClick={() => {
                  dispatch(setSelectedTruckOrigin(r));
                  openTruckModal(r);
                }}
                onContextMenu={onTruckContextMenu}
                tooltipData={r}
              />
            ))
          }
        </div>
      </PF2MScrollbar>
      <PF2MScrollbar
        className={classes.scrollbars}
        style={{ width: '50%' }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row-reverse',
            backgroundColor: '#dedede',
          }}
        >
          <PF2MEquipIcon
            item={origin}
            itemType={'load'}
            customStyles={{ marginRight: 15 }}
            onContextMenu={onLoadContextMenu}
            onClick={() => {
              if (!isGenericRow) {
                dispatch(setSelectedLoadOrigin(origin));
                openExcavatorModal(origin);
              }
            }}
            warningType={
              (!origin.priority && !isGenericRow ? 'standardWarning' : null)
            }
            tooltipData={origin}
          />
          {
            rightLaneDurations.map(r => (
              <PF2MEquipIcon
                key={r.equip_id}
                extraInfo={`${r.load_allocation_excavator_name}`}
                item={r}
                itemType={DispatchDragNDropTypes.ORIGIN_TRUCK}
                useOperationType={
                  !((r.operation_type === 11 || r.operation_type === 24))
                }
                customStyles={{
                  marginRight: 15,
                  opacity: (dragItem && dragItem.obj?.equip_id === r.equip_id) ? 0 : 1,
                }}
                onContextMenu={onTruckContextMenu}
                warningType={getWarningType(r, loadAllocations, isGenericRow)}
                onClick={() => {
                  dispatch(setSelectedTruckOrigin(r));
                  openTruckModal(r);
                }}
                tooltipData={r}
              />
            ))
          }
        </div>
      </PF2MScrollbar>
    </div>
  );
};

OriginRow.propTypes = {
  onTruckContextMenu: PropTypes.func,
  classes: PropTypes.object,
  onTruckDrop: PropTypes.func,
  origin: PropTypes.object,
  leftLane: PropTypes.array,
  rightLane: PropTypes.array,
  isGenericRow: PropTypes.bool,
  loadAllocations: PropTypes.array,
  openTruckModal: PropTypes.func,
  openExcavatorModal: PropTypes.func,
  onLoadContextMenu: PropTypes.func,
};

OriginRow.defaultProps = {
  classes: {},
  onTruckDrop: () => null,
  origin: {},
  leftLane: [],
  rightLane: [],
  isGenericRow: false,
  loadAllocations: [],
  openTruckModal: () => null,
  openExcavatorModal: () => null,
  onTruckContextMenu: null,
  onLoadContextMenu: null,
};

export default withStyles(styles)(OriginRow);
